import Form from './components/form_controller';

export default class MfaValidateVerifyController extends Form {
  static targets = [
    'input',
    'resendCodeButton',
    'resendCodeLimit',
    'resendCodeForm',
    'form',
    'pop'
  ];

  connect() {

    this.popTargetConnected({ currentTarget: this.popTarget });
    this.retryAttempts = parseInt(document.getElementById('attempts').value, 10);
    this.retryTimeouts = {
      1: 30,
      2: 60,
      3: 120,
      4: 240,
      5: 480,
    };
  }


  popTargetConnected(e) {

    if (!e || !e.currentTarget) {

      return;
    }


    const dispatchUrl = e.currentTarget.dataset.dispatchUrl || '/default-url';


    window.setTimeout(() => {
      window.location.href = dispatchUrl;
    }, 1000);
  }


  validate(e) {
    const codeController = this.getControllerByElement(this.inputTarget);
    const valid = codeController.validate();

    if (!valid) {
      e.preventDefault();
    }
  }

  getRetryTimeout(attempts) {
    return this.retryTimeouts[attempts] || 600;
  }

  updateCountdown(seconds) {
    const htmlContent = `Resend Code in <span class="body-semi-bold">${seconds}</span>s`;

    this.resendCodeLimitTarget.innerHTML = htmlContent;
  }

  startCountdown(seconds) {
    this.updateCountdown(seconds);

    const countdownInterval = setInterval(() => {
      seconds -= 1;
      this.updateCountdown(seconds);

      if (seconds <= 0) {
        clearInterval(countdownInterval);
        this.resendCodeButtonTarget.style.display = 'block';
        this.resendCodeLimitTarget.style.display = 'none';
      }
    }, 1000);
  }

  resendCode(e) {
    e.preventDefault();

    this.resendCodeButtonTarget.style.display = 'none';
    this.resendCodeLimitTarget.style.display = 'block';

    this.retryAttempts += 1;
    document.getElementById('attempts').value = this.retryAttempts;

    const timeout = this.getRetryTimeout(this.retryAttempts);
    this.startCountdown(timeout);
    this.resendCodeFormTarget.submit();
  }
}
