import Form from './components/form_controller';
import { post } from '@rails/request.js';

export default class UsersCreateEditController extends Form {
  hiddenClass = 'hidden';

  static targets = [
    'groupsDropdownContainer',
  ];

  static values = {
    staffRole: String,
    photocakeRole: String,
  };

  dropdownBlur(e) {
    const dropdown = e.detail.dropdown;
    if (!dropdown.getSelection()?.value || dropdown.initializing) return;

    if (dropdown.id === 'role' && e.detail.wasChanged) {
      // get value
      const value = dropdown.getSelection()?.value;

      // get path from var set on the locations dropdown
      const baseUrl = dropdown.streamValue;
      const url = `${baseUrl}?option=${value}`;

      // hide or show groups if the user chose staff or not
      if (value === this.staffRoleValue || value === this.photocakeRoleValue) {
        this.groupsDropdownContainerTarget.classList.add(this.hiddenClass);
      } else {
        this.groupsDropdownContainerTarget.classList.remove(this.hiddenClass);
      }

      post(encodeURI(url), {
        responseKind: 'turbo-stream',
      });
    }
  }
}
